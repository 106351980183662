import Axios from "axios";

export default class userService {
  static createUser = async (email) => {
    let response = null;
    let errorMessage = null;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_REWARDS}v1/user/`,
      data: JSON.stringify({
        user: email,
        email_verified: "False"
      })
    })
      .then(() => {
        response = true;
      })
      .catch((error) => {
        errorMessage = error;
      });
    return { response, errorMessage };
  };

  static getUser = async (email) => {
    let response = null;
    await Axios.get(
      `${process.env.REACT_APP_BASE_URL_API_HAZO_REWARDS}v1/user/?user=${email}`
    )
      .then((user) => {
        response = user.data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static updateUser = async (data) => {
    let response = null;
    let dataToRequest = JSON.stringify({
      user: data.user,
      email_verified: data.email_verified
    });
    await Axios.put(
      `${process.env.REACT_APP_BASE_URL_API_HAZO_REWARDS}v1/user/`,
      dataToRequest
    )
      .then((res) => {
        response = res.data.data;
      })
      .catch(() => {
        response = false;
      });
    return response;
  };
}
